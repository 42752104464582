// extracted by mini-css-extract-plugin
export const navbarWrapper = "styles-module--navbarWrapper--2fuL-";
export const bordered = "styles-module--bordered--3Vyor";
export const navbar = "styles-module--navbar--TNnmU";
export const title = "styles-module--title---A5ua";
export const navLinks = "styles-module--navLinks--1Ci7x";
export const navActive = "styles-module--navActive--2UNzg";
export const navLinkFade = "styles-module--navLinkFade--3yraE";
export const burger = "styles-module--burger--6Nhzt";
export const line1 = "styles-module--line1--2LMqM";
export const line2 = "styles-module--line2--2002u";
export const line3 = "styles-module--line3--2ZAyK";