export const es = {
    "title": 'Sneyder Angulo | Desarrollador de software',
    "header.home": 'Principal',
    "header.about": 'Sobre mí',
    "header.blog": 'Blog',
    "header.contact": 'Contacto',
    "header.projects": 'Proyectos',
    "home.title": 'Hola, Soy Sneyder Angulo',
    "home.subTitle": 'Un desarrollador de software apasionado',
    "home.what_do": '¿Lo que hago?',
    "projects.title": 'Aca estan algunos de mis proyectos',
    "projects.see_live": "Visitar sitio",
    "projects.github_repo": "Github repo",
    "projects.get_app": "Obtener App",
    "about.title": "Sobre mí",
    "about.bio": "Desarrollador de apps web y apps android nativas, actualmente cursando la carrera de Ingenieria en Informatica bajo la modalidad online, disponible para ser contratado a tiempo parcial o para proyectos de tipo freelance.",
    "about.skills": "Habilidades",
    "about.view_cv": "Ver Hoja de Vida",
    "contact.title": "Contáctame",
    "contact.subtitle": "Información de contacto",
    "contact.desc": "Envíe un mensaje en general o con los detalles de algún proyecto del que le gustaría que forme parte y me pondré en contacto con usted tan pronto como sea posible.",
    "contact.form.name": "Nombre",
    "contact.form.email": "Correo electrónico",
    "contact.form.message": "Mensaje",
    "contact.form.send": "Enviar mensaje",
    "contact.form.success": "Gracias por contactarme!, le respondere tan pronto como sea posible.",
    "footer.title": "Diseñado y creado por Sneyder Angulo © ",
};
