// extracted by mini-css-extract-plugin
export const projects = "styles-module--projects--2nn6E";
export const title = "styles-module--title--3SaKp";
export const items = "styles-module--items--1UcLt";
export const project = "styles-module--project--3d4Qq";
export const content = "styles-module--content--3LI31";
export const topSection = "styles-module--topSection--2-M5P";
export const description = "styles-module--description--9_iAt";
export const date = "styles-module--date--2EJLi";
export const buttonsWrapper = "styles-module--buttonsWrapper--3vxF4";
export const button = "styles-module--button--10pfC";
export const tagsWrapper = "styles-module--tagsWrapper--1SLYO";
export const tag = "styles-module--tag--2LA09";